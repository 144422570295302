.navbar-toggler {
    width: 44px;
    height: 38px;
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 99;
    border: none;
    background: var(--prime-one);
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-toggler::before,
  .navbar-toggler::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 9px;
    width: 26px;
    height: 2px;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: #fff;
  }
  .navbar-toggler span {
    position: absolute;
    left: 9px;
    overflow: hidden;
    width: 26px;
    height: 2px;
    margin-top: -1px;
    text-indent: 200%;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
    background: #fff;
  }
  .navbar-toggler::before {
    -webkit-transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
    transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
  }
  .navbar-toggler::after {
    -webkit-transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
  }
  .navbar-toggler[aria-expanded="true"] span {
    opacity: 0;
  }
  .navbar-toggler[aria-expanded="true"]::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
  }
  .navbar-toggler[aria-expanded="true"]::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
  }
  .navbar .navbar-nav .nav-link {
    font-weight: 500;
    font-size: 18px;
    line-height: initial;
    color: #000;
    padding: 13px 0;
    margin: 0 26px;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  .theme-main-menu.theme-menu-nine .navbar .navbar-nav .nav-link {
    @media (min-width: 1400px) and (max-width: 1700px) {
      margin: 0 22px;
    }
  }
  .navbar .navbar-nav .nav-item.active .nav-link,
  .navbar .navbar-nav .nav-item:hover .nav-link {
    color: var(--prime-one);
  }
  /*Menu Two*/
  .theme-menu-two .navbar .navbar-nav .nav-item.active .nav-link,
  .theme-menu-two .navbar .navbar-nav .nav-item:hover .nav-link,
  .theme-menu-two .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-two .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-two .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-two);
  }
  .theme-menu-two .navbar-toggler {
    background: var(--prime-two);
  }
  /*Menu Three*/
  .theme-menu-three .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-three .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-three .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-three);
  }
  /*Menu Four*/
  .theme-menu-four .navbar-toggler {
    background: #815aff;
  }
  /*Menu Five*/
  .theme-menu-five .navbar-toggler {
    background: var(--prime-four);
  }
  /*Menu Six*/
  .theme-menu-six .navbar .navbar-nav .nav-item.active .nav-link,
  .theme-menu-six .navbar .navbar-nav .nav-item:hover .nav-link,
  .theme-menu-six .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-six .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-six .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-six);
  }
  .theme-menu-six .navbar-toggler {
    background: var(--prime-six);
  }
  /*Menu Seven*/
  .theme-menu-seven .navbar .navbar-nav .nav-item.active .nav-link,
  .theme-menu-seven .navbar .navbar-nav .nav-item:hover .nav-link,
  .theme-menu-seven .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-seven .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-seven .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-nine);
  }
  .theme-menu-seven .navbar-toggler {
    background: var(--prime-nine);
  }
  /*Menu Eight*/
  .theme-menu-eight .navbar .navbar-nav .nav-item.active .nav-link,
  .theme-menu-eight .navbar .navbar-nav .nav-item:hover .nav-link,
  .theme-menu-eight .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-eight .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-eight .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-ten);
  }
  .theme-menu-eight .navbar-toggler {
    background: var(--prime-ten);
  }
  /*Menu Nine*/
  .theme-menu-nine .navbar .navbar-nav .nav-item.active .nav-link,
  .theme-menu-nine .navbar .navbar-nav .nav-item:hover .nav-link,
  .theme-menu-nine .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-nine .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-nine .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-two);
  }
  .theme-menu-nine .navbar-toggler {
    background: var(--prime-two);
  }
  /*Menu Ten*/
  .theme-menu-ten .navbar-toggler {
    background: var(--prime-twelve);
  }
  /*Menu Eleven*/
  .theme-menu-eleven .navbar .navbar-nav .nav-item.active .nav-link,
  .theme-menu-eleven .navbar .navbar-nav .nav-item:hover .nav-link,
  .theme-menu-eleven .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .theme-menu-eleven .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu-eleven .navbar .dropdown-menu .dropdown-item:hover {
    color: #ff5ea3;
  }
  .theme-menu-eleven .navbar-toggler {
    background: linear-gradient(270.78deg, #ffa7a9 4.19%, #ff5ea3 96.11%);
  }
  
  .navbar .dropdown-menu .dropdown-item {
    font-size: 16px;
    padding: 0 15px;
    letter-spacing: 0px;
    text-transform: capitalize;
    line-height: 44px;
    color: #000;
    background: transparent;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;
    white-space: inherit;
  }
  .navbar .dropdown-menu .dropdown-item .tag {
    position: absolute;
    background: var(--red-one);
    line-height: 15px;
    color: #fff;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 0 4px;
    text-transform: uppercase;
    top: 0;
    right: 0;
    font-style: normal;
  }
  .navbar .dropdown-menu .dropdown:hover > .dropdown-item,
  .navbar .dropdown-menu .dropdown-item.active,
  .navbar .dropdown-menu .dropdown-item:hover {
    color: var(--prime-one);
  }
  .navbar .dropdown-toggle::after {
    content: "\f282";
    font-family: bootstrap-icons;
    border: none !important;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    line-height: 56px;
    right: auto;
    top: 0;
    text-align: center;
    /*  transition: all 0.3s ease;*/
  }
  .navbar .show.dropdown-toggle::after {
    transform: rotate(180deg);
  }
  .navbar .dropdown-submenu .dropdown-toggle::after {
    content: "\f285";
    font-family: bootstrap-icons !important;
    display: block;
    right: 15px;
    top: 0;
    background: transparent !important;
    color: inherit;
    width: auto;
    height: auto;
    line-height: 44px;
    font-weight: 700;
    font-size: 11px;
    transition: transform 0.3s ease-in-out;
  }
  .navbar .dropdown-submenu:hover > .dropdown-toggle::after {
    transform: rotate(90deg);
  }
  
  /*---------------- Mega Dropdown ------------------*/
  .theme-main-menu .mega-dropdown {
    position: static;
  }
  .theme-main-menu .mega-dropdown-md .dropdown-menu {
    min-width: 660px;
  }
  .navbar [class*="mega-dropdown"] .dropdown-menu {
    padding: 0 30px 15px;
    margin: 0;
  }
  .navbar [class*="mega-dropdown"] .menu-column {
    padding: 15px 0 15px;
  }
  .navbar [class*="mega-dropdown"] .mega-menu-title {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    display: inline-block;
    position: relative;
    margin: 15px 0 18px;
  }
  .navbar [class*="mega-dropdown"] .mega-menu-title:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #000;
    left: 0;
    bottom: -5px;
  }
  .navbar [class*="mega-dropdown"] .dropdown-item {
    line-height: 40px;
    padding: 0;
  }
  /*----- For Desktop -------*/
  @media screen and (min-width: 992px) {
    .theme-main-menu .navbar {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .theme-main-menu.theme-menu-nine .navbar,
    .theme-main-menu.theme-menu-five .navbar,
    .theme-main-menu.theme-menu-ten .navbar,
    .theme-main-menu.theme-menu-four .navbar {
      position: relative;
      left: 0;
      transform: none;
    }
  
    .navbar .dropdown-menu {
      z-index: 5;
      background-color: #fff;
      border-radius: 5px;
      display: block;
      right: 0;
      left: 0;
      padding: 10px 5px;
      border: none;
      top: 100%;
      visibility: hidden;
      transform: translateY(15px);
      opacity: 0;
      min-width: 250px;
      box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
      margin: 0;
      transform-origin: 0 0;
      transition: all 0.3s ease-in-out;
    }
    .navbar .dropdown-menu .dropdown-menu {
      left: calc(100% + 5px);
      top: 0;
      right: auto;
      min-width: 240px;
      box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
      transform: translateY(0);
    }
    .navbar .dropdown:hover > .dropdown-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
  /*(min-width: 992px)*/
  
  /*----- For Mobile ----*/
  @media screen and (max-width: 991px) {
    .navbar {
      padding: 0;
    }
    .navbar-collapse .logo {
      margin-bottom: 8vh;
    }
    .navbar .dropdown-toggle::after {
      right: 0;
      line-height: 50px;
      font-size: 15px;
    }
    .navbar-collapse {
      position: fixed;
      top: 0;
      background-color: #ffffff;
      left: 0;
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      clear: both;
      width: 320px;
      max-width: calc(100vw - 60px);
      z-index: 9999;
      transform: translateX(-100%);
      display: block !important;
      padding: 32px 15px 20px;
      transition: all 0.3s ease-in-out;
    }
    .white-vr .navbar-collapse {
      background: #151937;
    }
    .navbar-collapse.show {
      transform: translateX(0);
      box-shadow: 15px 0 25px rgba(35, 35, 35, 0.09);
    }
    .theme-main-menu .navbar .mega-dropdown {
      position: relative;
    }
    .navbar .navbar-nav .nav-link {
      margin: 0;
      padding: 13px 0;
      font-size: 16px;
    }
    .navbar .dropdown-menu .dropdown-item {
      padding: 0 10px;
      font-size: 15px;
      line-height: 42px;
    }
    .navbar .dropdown-menu .dropdown-item.dropdown-toggle {
      font-weight: 500;
    }
    .navbar .dropdown-submenu .dropdown-toggle::after {
      right: 15px;
      font-size: 13px;
    }
    .navbar .dropdown-submenu:hover > .dropdown-toggle::after {
      transform: rotate(0deg);
    }
    .navbar .dropdown-submenu .dropdown-toggle.show::after {
      transform: rotate(90deg);
    }
    .navbar .dropdown-menu {
      border: none;
      padding: 0;
      border-radius: 0;
      margin: 0;
      background: #eefbfa;
    }
    .theme-menu-nine .navbar .dropdown-menu {
      background: #fbf4ee;
    }
    .navbar .dropdown-submenu .dropdown-menu {
      margin: 0;
      padding: 0 0 0 10px;
    }
    .navbar [class*="mega-dropdown"] .dropdown-menu {
      padding: 0 15px;
    }
    .theme-main-menu .mega-dropdown-md .dropdown-menu {
      min-width: 100%;
    }
    .navbar [class*="mega-dropdown"] .mega-menu-title {
      margin: 0 0 12px;
    }
    .navbar [class*="mega-dropdown"] .menu-column {
      padding-bottom: 0;
    }
  }
  /*(max-width: 991px)*/
  