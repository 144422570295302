/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 6, 2022 */



@font-face {
    font-family: 'eustacheregular';
    src: url('eustache-regular-webfont.woff2') format('woff2'),
         url('eustache-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}